import Vue from 'vue'
import * as actionHelper from '@/modules/app/helpers/actions'
import Group from '@/modules/groups/models/group'

export default {
  getAllGroups (context, payload) {
    return new Promise((resolve, reject) => {
      const url = actionHelper.prepareGetAllURL(payload, 'group', 'categories')
      return Vue.axios.get(url)
        .then(res => resolve(
          {
            items: Group.fromJsonArray(res.data.data),
            pagination: res.data.meta.pagination
          }))
        .catch(err => reject(err))
    })
  },
  getGroup (store, payload) {
    return new Promise((resolve, reject) => {
      return Vue.axios.get('/group/' + payload.id).then(
        res => resolve(Group.fromJson(res.data.data))
      ).catch(err => reject(err))
    })
  },
  addGroupMember (store, payload) {
    const params = actionHelper.urlSearchParamsFromProperties(payload)
    return Vue.axios.post(`group/${payload.id}/member`, params)
  }
}
