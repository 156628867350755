<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <kurcc-image-input :hide-details="false" :image.sync="localItem" :name="title" :outlined="false"
                           :rules="schema.rules"/>
      </v-col>
      <v-col v-if="localItem" class="mx-auto" cols="auto">
        <kurcc-image-preview :alt="`${schema.item.title} photo`" :loading="compressing" :src="localItem"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { toBase64, updateQuestionField } from '@/modules/inspection/helpers/helpers'
import Compressor from 'compressorjs'

export default {
  name: 'KurccFormImage',
  mixins: [abstractField],
  components: {
    KurccImageInput: () => import('@/modules/inspection/components/KurccImageInput'),
    KurccImagePreview: () => import('@/modules/inspection/components/KurccImagePreview')
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    schema: Object
  },
  data () {
    return {
      updateQuestionField,
      compressing: false
    }
  },
  computed: {
    title () {
      return this.$lodash.isEmpty(this.schema.item.title) ? 'This field' : this.schema.item.title
    },
    indexes () {
      return this.schema.indexes
    },
    localItem: {
      get () {
        return this.schema.item.answer
      },
      set (v) {
        if (v && typeof v !== 'string') {
          this.compressAndUpdateImageField(v)
        } else {
          this.updateQuestionField('answer', v, this.indexes)
        }
      }
    }
  },
  methods: {
    compressAndUpdateImageField (file) {
      this.compressing = true
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 2000,
        minWidth: 2000,
        mimeType: 'image/jpeg',
        success: (result) => {
          toBase64(result, base64 => this.updateQuestionField('answer', base64, this.indexes))
          this.compressing = false
        },
        error (err) {
          console.log('err', err.message)
          this.compressing = false
        }
      })
    }
  }
}
</script>
