import actions from '@/modules/inspection/store/actions'
import getters from '@/modules/inspection/store/getters'
import mutations from '@/modules/inspection/store/mutations'
import { defaultForm } from '@/modules/inspection/constants/default-form'

export default {
  state: () => ({
    form: defaultForm
  }),
  actions: actions,
  getters: getters,
  mutations: mutations
}
