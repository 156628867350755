import dayjs from 'dayjs'
import Site from '@/modules/app/models/site'
import Branch from '@/modules/app/models/branch'
import Staff from '@/modules/app/models/staff'
import Group from '@/modules/groups/models/group'

export default class Inspection {
  constructor (
    id = null,
    deadlineDays = undefined,
    score = 0,
    grade = 0,
    type = '',
    site = new Site(),
    branch = new Branch(),
    inspector = new Staff(),
    group = new Group(),
    address = '',
    inspectorName = '',
    appealOfInspection = '',
    inspectionDueDate = dayjs().format('YYYY-MM-DD'),
    inspectionStartedAt = '',
    inspectionEndedAt = '',
    isToday = '',
    createdAt = '',
    object = '',
    resultId = null,
    status = null
  ) {
    this.id = id
    this.deadline_days = deadlineDays
    this.score = score
    this.grade = grade
    this.type = type
    this.site = site
    this.branch = branch
    this.inspector = inspector
    this.group = group
    this.address = address
    this.inspector_name = inspectorName
    this.appeal_of_inspection = appealOfInspection
    this.inspection_due_date = inspectionDueDate
    this.inspection_started_at = inspectionStartedAt
    this.inspection_ended_at = inspectionEndedAt
    this.is_today = isToday
    this.created_at = createdAt
    this.object = object
    this.result_id = resultId
    this.status = status
  }

  static fromJson (json) {
    return new Inspection(
      json.id,
      json.deadline_days,
      json.score,
      json.grade,
      json.type,
      json.site ? Site.fromJson(json.site.data) : null,
      json.branch ? Branch.fromJson(json.branch.data) : null,
      json.inspector ? Staff.fromJson(json.inspector.data) : null,
      json.group ? Group.fromJson(json.group.data) : null,
      json.branch?.data.area + ', ' + json.branch?.data.address,
      json.inspector?.data.first_name + ' ' + json.inspector?.data.last_name,
      json.appeal_of_inspection,
      dayjs(json.inspection_due_date).format('YYYY-MM-DD'),
      dayjs(json.inspection_started_at).format('YYYY-MM-DD'),
      dayjs(json.inspection_ended_at).format('YYYY-MM-DD'),
      json.is_today,
      dayjs(json.created_at).format('YYYY-MM-DD'),
      json.object,
      json.result_id,
      json.status
    )
  }

  static fromJsonArray (json) {
    const result = []
    json.forEach(inspection => {
      result.push(this.fromJson(inspection))
    })
    return result
  }

  isDone () {
    return this.status === 'done'
  }
}
