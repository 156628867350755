import Page from '@/modules/inspection/models/page'
import Question from '@/modules/inspection/models/question'
import dayjs from 'dayjs'
import store from '@/modules/app/store'

export default class Form {
  constructor (
    id = null,
    title = 'Form 01',
    description = null,
    pages = [new Page(null, [new Question()], new Date().getTime())],
    object = null,
    enabled = null,
    isDraft = null,
    createdAt = null,
    responseSets = store.state.inspection.form.responseSets,
    version = 1
  ) {
    this.id = id
    this.title = title
    this.description = description
    this.pages = pages
    this.object = object
    this.enabled = enabled
    this.is_draft = isDraft
    this.is_draft_datatable_display_ready = isDraft ? 'Yes' : 'No'
    this.created_at = createdAt
    this.responseSets = responseSets
    this.version = version
  }

  static fromJson (json) {
    let parsedForm
    if (json.data) {
      parsedForm = JSON.parse(json.data)
    } else {
      parsedForm = JSON.parse(json)
    }

    return new Form(
      json.id,
      parsedForm.title,
      parsedForm.description,
      parsedForm.pages,
      json.object,
      json.enabled,
      json.is_draft,
      dayjs(json.created_at).format('YYYY-MM-DD'),
      parsedForm.responseSets,
      json.version
    )
  }

  static fromJsonArray (json) {
    const result = []
    json.forEach(form => {
      result.push(this.fromJson(form))
    })
    return result
  }
}
