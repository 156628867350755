import Vue from 'vue'
import { ValidationProvider } from 'vee-validate'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueFormGenerator from 'vue-form-generator'
import '@/modules/inspection/custom-fields-registration'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.component(VueCountdown.name, VueCountdown)
Vue.component('ValidationProvider', ValidationProvider)
Vue.use(VueFormGenerator)
Vue.use(DatetimePicker)
