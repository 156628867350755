import lodash from 'lodash'
import { vuetify } from '@/plugins'

export const removeItemFromArray = (item, array) => {
  const index = lodash.findIndex(array, o => o.id === item.id)
  if (index >= 0) array.splice(index, 1)
}

export const changeLocale = (locale) => {
  setLocale(locale)
  setRTL(locale)
}
export const setLocale = (locale) => {
  localStorage.setItem('locale', locale)
  vuetify.framework.lang.current = locale
}
export const getLocale = () => {
  const savedLocale = localStorage.getItem('locale')
  if (savedLocale) {
    return savedLocale
  } else {
    return vuetify.framework.lang.current
  }
}
export const setRTL = (locale) => {
  switch (locale) {
    case 'ar':
      vuetify.framework.rtl = true
      break
    case 'en':
      vuetify.framework.rtl = false
      break
  }
}
