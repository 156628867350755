<template>
  <v-app v-if="$auth.ready()" id="app">
    <v-main>
      <v-container class="align-start fill-height" fluid>
        <kurcc-app-bar v-if="showHeaderAndFooter"/>
        <router-view/>
        <kurcc-bottom-navigation v-if="showHeaderAndFooter"/>
      </v-container>
    </v-main>
    <kurcc-snackbar/>
  </v-app>
  <v-app v-else id="app">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-progress-circular indeterminate/>
        </v-row>
      </v-container>
    </v-main>
    <kurcc-snackbar/>
  </v-app>
</template>

<script>
import { RESET_FILTER } from '@/modules/app/store/mutation-types'
import { changeLocale, getLocale } from '@/modules/app/helpers/utils'

export default {
  name: 'App',
  data () {
    return {
      changeLocale,
      getLocale
    }
  },
  components: {
    KurccSnackbar: () => import('@/modules/app/components/KurccSnackbar'),
    KurccAppBar: () => import('@/modules/app/components/KurccAppBar'),
    KurccBottomNavigation: () => import('@/modules/app/components/KurccBottomNavigation')
  },
  computed: {
    isLoginPage () {
      return this.$route.name === 'login' || this.$route.name === 'login-index' || this.$route.name === 'login-verify-code'
    },
    is404Page () {
      return this.$route.name === 'not-found'
    },
    is403Page () {
      return this.$route.name === 'forbidden'
    },
    showHeaderAndFooter () {
      return !this.isLoginPage && !this.is403Page && !this.is404Page
    }
  },
  watch: {
    $route (to, from) {
      if (from.name !== to.name) {
        this.$store.commit(RESET_FILTER)
      }
    }
  },
  created () {
    this.changeLocale(getLocale())
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.center-x {
  left: 50%;
  transform: translateX(-50%);
}

.center-center {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-tabs-bar.v-tabs-bar--is-mobile .v-tab {
  margin-left: 0 !important;
}
</style>
