import Vue from 'vue'
import Form from '@/modules/inspection/models/form'
import { QuestionType } from '@/modules/inspection/enums/question-type'
import lodash from 'lodash'
import { RESET_INSPECTION_FORM, UPDATE_INSPECTION_FORM } from '@/modules/app/store/mutation-types'

export default {
  getForm ({ commit }, payload) {
    return Vue.axios.get('/inspection/' + payload.id + '/result/' + payload.form_id).then(
      res => commit(UPDATE_INSPECTION_FORM, Form.fromJson(res.data.data.form_data))
    ).catch(err => console.log(err))
  },
  startInspection ({ commit }, payload) {
    return Vue.axios.post('/inspection/' + payload.id + '/start').then(
      res => commit(UPDATE_INSPECTION_FORM, Form.fromJson(res.data.data.form_data))
    ).catch(err => console.log(err))
  },
  submitInspection ({
    state,
    commit
  }, payload) {
    const score = calculateFormScore(state.form)
    return new Promise((resolve, reject) => {
      return Vue.axios.post('/inspection/' + payload.id + '/submit', {
        is_draft: 0,
        form_data: JSON.stringify(state.form),
        score
      }).then(value => {
        commit(RESET_INSPECTION_FORM)
        resolve(value)
      }).catch(err => reject(err))
    })
  }
}

const calculateFormScore = (form) => {
  if (lodash.isNil(form)) return

  let score = 0
  for (let i = 0; i < form.pages.length; i++) {
    score += calculatePageScore(form.pages[i])
  }
  return score
}

const calculatePageScore = (page) => {
  let score = 0
  for (let i = 0; i < page.questions.length; i++) {
    if (page.questions[i].type === QuestionType.section) {
      score += calculatePageScore(page.questions[i])
    }
    if (page.questions[i].type === QuestionType.check) {
      score += page.questions[i].score ?? 0
    }
  }
  return score
}
