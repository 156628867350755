export default [
  {
    path: '/groups',
    name: 'groups',
    component: () => import('../pages/KurccGroupsPage'),
    meta: {
      auth: true
    }
  },
  {
    path: '/group/:group_id',
    name: 'group',
    component: () => import('../pages/KurccGroupPage'),
    props: true,
    meta: {
      auth: true
    }
  }
]
