export default [
  {
    path: '/upcoming',
    name: 'upcoming',
    component: () => import('../pages/KurccUpcomingPage'),
    meta: {
      auth: true
    }
  }
]
