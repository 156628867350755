import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/modules/app/store/modules'
import mutations from '@/modules/app/store/mutations'
import getters from '@/modules/app/store/getters'
import actions from '@/modules/app/store/actions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: {
      show: false,
      message: null,
      color: null
    },
    search: {
      query: '',
      isSearching: false,
      isSearchBoxOpen: false
    },
    filter: {
      sortedBy: 'desc',
      orderBy: 'created_at',
      assignmentStatus: null,
      groupAssignmentStatus: null,
      categories: null,
      groups: null,
      inspectionType: {
        routine: false,
        custom: false,
        revisit: false
      }
    }
  },
  actions: actions,
  getters: getters,
  mutations: mutations,
  strict: process.env.NODE_ENV !== 'production',
  modules // ES6 shorthand for modules: modules
})
