import Vue from 'vue'
import * as actionHelper from '@/modules/app/helpers/actions'
import Staff from '@/modules/app/models/staff'
import Category from '@/modules/app/models/category'

export default {
  getAllStaff (context, payload) {
    return new Promise((resolve, reject) => {
      const url = actionHelper.prepareGetAllURL(payload, 'staff', 'roles')
      return Vue.axios.get(url)
        .then(res => resolve(
          {
            items: Staff.fromJsonArray(res.data.data),
            pagination: res.data.meta.pagination
          }))
        .catch(err => reject(err))
    })
  },
  getAllCategories (context, payload) {
    return new Promise((resolve, reject) => {
      const url = actionHelper.prepareGetAllURL(payload, 'category', 'groups')
      return Vue.axios.get(url)
        .then(res => resolve(
          {
            items: Category.fromJsonArray(res.data.data),
            pagination: res.data.meta.pagination
          }))
        .catch(err => reject(err))
    })
  }
}
