import Vue from 'vue'
import KurccFormSignatureResponse from '@/modules/inspection/components/custom-fields/KurccFormSignatureResponse'
import KurccFormMultiChoiceResponse from '@/modules/inspection/components/custom-fields/KurccFormMultiChoiceResponse'
import KurccFormDropdownResponse from '@/modules/inspection/components/custom-fields/KurccFormDropdownResponse'
import KurccFormSection from '@/modules/inspection/components/custom-fields/KurccFormSection'
import KurccFormDateTimeResponse from '@/modules/inspection/components/custom-fields/KurccFormDateTimeResponse'
import KurccFormText from '@/modules/inspection/components/custom-fields/KurccFormText'
import KurccFormNumber from '@/modules/inspection/components/custom-fields/KurccFormNumber'
import KurccFormCheckbox from '@/modules/inspection/components/custom-fields/KurccFormCheckbox'
import KurccFormImage from '@/modules/inspection/components/custom-fields/KurccFormImage'

Vue.component('fieldKurccFormSignatureResponse', KurccFormSignatureResponse)
Vue.component('fieldKurccFormMultiChoiceResponse', KurccFormMultiChoiceResponse)
Vue.component('fieldKurccFormDropdownResponse', KurccFormDropdownResponse)
Vue.component('fieldKurccFormSection', KurccFormSection)
Vue.component('fieldKurccFormDateTimeResponse', KurccFormDateTimeResponse)
Vue.component('fieldKurccFormText', KurccFormText)
Vue.component('fieldKurccFormNumber', KurccFormNumber)
Vue.component('fieldKurccFormCheckbox', KurccFormCheckbox)
Vue.component('fieldKurccFormImage', KurccFormImage)
