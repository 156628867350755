<template>
  <div>
    <validation-provider v-slot="{ errors }" :name="title" :rules="schema.rules" class="w-100">
      <v-select v-model="selectedItems" :error-messages="errors" :items="items" :multiple="isMultiSelect"
                chips dense/>
    </validation-provider>
    <kurcc-form-multi-choice-additional-buttons :indexes="indexes" :item.sync="item"/>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { updateQuestionField } from '@/modules/inspection/helpers/helpers'

export default {
  name: 'KurccFormDropdownResponse',
  mixins: [abstractField],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    schema: Object
  },
  components: {
    KurccFormMultiChoiceAdditionalButtons: () => import('@/modules/inspection/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtons')
  },
  data () {
    return {
      selectedItems: [],
      updateQuestionField
    }
  },
  computed: {
    item () {
      return this.schema.item
    },
    items () {
      return this.schema.values
    },
    isMultiSelect () {
      return this.schema.item.options.multiSelect
    },
    title () {
      return this.$lodash.isEmpty(this.schema.item.title) ? 'This field' : this.schema.item.title
    },
    indexes () {
      return this.schema.indexes
    }
  },
  watch: {
    isMultiSelect: function () {
      this.selectedItems = []
    },
    selectedItems: function (v) {
      this.updateQuestionField('answer', v, this.indexes)
    }
  },
  methods: {
    setPreviouslySelectedAnswer () {
      this.selectedItems = this.item.answer
    }
  },
  created () {
    this.setPreviouslySelectedAnswer()
  }
}
</script>
