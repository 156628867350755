import { QuestionType } from '@/modules/inspection/enums/question-type'

export default class Question {
  constructor (
    title = null,
    type = QuestionType.check,
    options = null,
    questions = [],
    answer = null,
    score = 0,
    id = new Date().getTime()
  ) {
    this.id = id
    this.title = title
    this.type = type
    this.questions = questions
    this.answer = answer
    this.score = score
    this.options = options
  }

  setNewId () {
    this.id = new Date().getTime()
  }
}
