<template>
  <v-row justify="start" no-gutters>
    <v-col class="text-start" cols="12">
      <span class="text-start text-h6">{{ item.title }}</span>
    </v-col>
    <v-col v-if="item.options.repeatSection && !item.options.parentId" class="text-start" cols="12">
      <v-btn color="primary" outlined small @click="cloneQuestionAfter">Add {{ item.title }}</v-btn>
    </v-col>
    <v-col v-if="item.options.parentId" class="text-start" cols="12">
      <v-btn color="primary" outlined small @click="deleteQuestion">Delete {{ item.title }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { CLONE_INSPECTION_FORM_QUESTION, DELETE_INSPECTION_FORM_QUESTION } from '@/modules/app/store/mutation-types'

export default {
  name: 'KurccFormSection',
  mixins: [abstractField],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    item () {
      return this.schema.item
    },
    indexes () {
      return this.schema.indexes
    }
  },
  methods: {
    cloneQuestionAfter () {
      const insertIndex = this.indexes.parentIndex

      this.$store.commit(CLONE_INSPECTION_FORM_QUESTION, {
        ...this.indexes,
        insertIndex,
        question: this.item,
        parentId: this.item.id
      })
    },
    deleteQuestion () {
      this.$store.commit(DELETE_INSPECTION_FORM_QUESTION, this.indexes)
    }
  }
}
</script>
