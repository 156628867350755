export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../pages/KurccSettingsPage.vue'),
    meta: {
      auth: true
    }
  }
]
