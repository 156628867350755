import {
  UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD,
  UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD
} from '@/modules/app/store/mutation-types'
import store from '@/modules/app/store'

export const updateQuestionField = (field, value, indexes) => {
  const {
    pageIndex,
    grandparentIndex,
    parentIndex,
    questionIndex
  } = indexes

  store.commit(UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD, {
    pageIndex,
    grandparentIndex,
    parentIndex,
    questionIndex,
    field,
    value
  })
}

export const updateQuestionOptionField = (field, value, indexes) => {
  const {
    pageIndex,
    grandparentIndex,
    parentIndex,
    questionIndex
  } = indexes

  store.commit(UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD, {
    pageIndex,
    grandparentIndex,
    parentIndex,
    questionIndex,
    field,
    value
  })
}

export const urlToFile = (url, filename, mimeType) => {
  return (fetch(url)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType })
    }))
}

export const toBase64 = (file, callback) => {
  // let selectedFile = document.getElementById("imageInput").files;
  // let imageFile = selectedFile[0];
  if (file) {
    const fileReader = new FileReader()
    fileReader.onload = fileLoadedEvent => {
      const srcData = fileLoadedEvent.target.result
      // let newImage = document.createElement('img');
      // newImage.src = srcData;
      callback(srcData)
      // console.log(srcData)
      // document.getElementById("dummy").innerHTML = newImage.outerHTML;
      // document.getElementById("txt").value = document.getElementById("dummy").innerHTML;
    }
    fileReader.readAsDataURL(file)
  }
}

export const fromBase64 = (url, filename = 'image', mimeType = 'image/jpeg') => {
  return (fetch(url)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType })
    }))
}
