<template>
  <v-container>
    <validation-provider ref="provider" v-slot="{ errors }"
                         :name="item.title ? item.title : 'This field'" :rules="schema.rules" class="w-100">
      <v-row justify="start">
        <v-btn v-for="(item, index) in items" :key="index"
               :color="item.color.hex"
               :outlined="!isSelected(item)"
               class="mb-1 mx-1" small @click="onClick(item)">
          {{ item.label }}
        </v-btn>
      </v-row>
      <kurcc-form-multi-choice-additional-buttons :indexes="indexes" :item="item"/>
      <v-text-field v-model="item.answer" :error-messages="errors" class="d-none"/>
      <div v-if="errors.length" class="v-application v-messages error--text v-messages__message">
        {{ errors[0] }}
      </div>
    </validation-provider>
  </v-container>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { updateQuestionField } from '@/modules/inspection/helpers/helpers'

export default {
  name: 'KurccFormMultiChoiceResponse',
  mixins: [abstractField],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    schema: {
      type: Object,
      required: true
    }
  },
  components: {
    KurccFormMultiChoiceAdditionalButtons: () => import('@/modules/inspection/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtons')
  },
  data () {
    return {
      selectedItems: [],
      updateQuestionField
    }
  },
  computed: {
    item () {
      return this.schema.item
    },
    items () {
      return this.schema.values
    },
    isMultiSelect () {
      return this.schema.item.options.multiSelect
    },
    isSelected () {
      return item => this.getSelectedIndex(item) !== -1
    },
    indexes () {
      return this.schema.indexes
    },
    weight () {
      return this.schema.weight
    }
  },
  watch: {
    isMultiSelect: function () {
      this.selectedItems = []
    },
    selectedItems: {
      handler: function (v) {
        this.updateQuestionField('answer', v, this.indexes)
        this.updateQuestionField('score', this.score(v), this.indexes)
        this.$nextTick(() => this.validate())
      }
    }
  },
  methods: {
    getSelectedIndex (item) {
      return this.$lodash.findIndex(this.selectedItems, itemInSelect => this.$lodash.isEqual(itemInSelect, item))
    },
    onClick (item) {
      const index = this.getSelectedIndex(item)
      if (index === -1) {
        if (!this.isMultiSelect) {
          this.selectedItems = []
        }

        this.addItem(item)
      } else {
        this.removeItem(index)
      }
    },
    addItem (item) {
      const si = [...this.selectedItems]
      si.push(item)
      this.selectedItems = si
    },
    removeItem (index) {
      const si = [...this.selectedItems]
      si.splice(index, 1)
      this.selectedItems = si
    },
    validate () {
      this.$refs.provider.validate()
    },
    setPreviouslySelectedAnswer () {
      this.selectedItems = this.item.answer ?? []
    },
    score (selectedItems) {
      let totalScore = 0
      if (selectedItems) {
        for (let i = 0; i < selectedItems.length; i++) {
          const score = selectedItems[i].score * this.weight * (selectedItems[i].negative ? -1 : 1)
          totalScore += score
        }
      }
      return totalScore
    }
  },
  created () {
    this.setPreviouslySelectedAnswer()
  }
}
</script>
