import * as actionHelper from '@/modules/app/helpers/actions'
import Vue from 'vue'
import Site from '@/modules/app/models/site'
import Branch from '@/modules/app/models/branch'

export default {
  getAllSites (context, payload) {
    return new Promise((resolve, reject) => {
      const url = actionHelper.prepareGetAllURL(payload, 'site', 'category')
      return Vue.axios.get(url)
        .then(res => resolve(
          {
            items: Site.fromJsonArray(res.data.data),
            pagination: res.data.meta.pagination
          }))
        .catch(err => reject(err))
    })
  },
  getAllBranches (context, payload) {
    return new Promise((resolve, reject) => {
      const url = actionHelper.prepareGetAllURL(payload, 'branch', ['site', 'inspections'])
      return Vue.axios.get(url)
        .then(res => resolve(
          {
            items: Branch.fromJsonArray(res.data.data),
            pagination: res.data.meta.pagination
          }))
        .catch(err => reject(err))
    })
  }
}
