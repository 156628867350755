import dayjs from 'dayjs'
import Site from '@/modules/app/models/site'
import Inspection from '@/modules/inspections/models/inspection'

export default class Branch {
  constructor (
    id = null,
    number = '',
    object = '',
    brandName = '',
    area = '',
    address = '',
    fullAddress = '',
    managerName = '',
    managerPhoneNumber = '',
    site = null,
    lastDoneInspection = null,
    nextInspection = null,
    inspection = null,
    createdAt = ''
  ) {
    this.id = id
    this.number = number
    this.object = object
    this.brand_name = brandName
    this.area = area
    this.address = address
    this.full_address = fullAddress
    this.manager_name = managerName
    this.manager_phone_number = managerPhoneNumber
    this.site = site
    this.last_done_inspection = lastDoneInspection
    this.next_inspection = nextInspection
    this.inspection = inspection
    this.created_at = createdAt
  }

  static fromJson (json) {
    return new Branch(
      json.id,
      json.number,
      json.object,
      json.brand_name,
      json.area,
      json.address,
      json.area + ', ' + json.address,
      json.manager_name,
      json.manager_phone_number,
      json.site ? Site.fromJson(json.site.data) : null,
      json.last_done_inspection ? Inspection.fromJson(json.last_done_inspection) : null,
      json.next_inspection ? Inspection.fromJson(json.next_inspection) : null,
      json.inspection ? Inspection.fromJson(json.inspection.data) : null,
      dayjs(json.created_at).format('YYYY-MM-DD'))
  }

  static fromJsonArray (json) {
    const result = []
    json.forEach(staff => {
      result.push(this.fromJson(staff))
    })
    return result
  }
}
