import Vue from 'vue'
import * as actionHelper from '@/modules/app/helpers/actions'

export default {
  updateUser (store, payload) {
    const params = actionHelper.formDataFromProperties(payload, {}, [], ['first_name', 'last_name', 'email', 'password'])

    params.set('_method', 'patch')
    return Vue.axios.post('/user/' + payload.id, params)
  }
}
