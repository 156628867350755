<template>
  <v-container>
    <kurcc-date-picker
      v-if="options.showDate && !options.showTime" :date.sync="localItem"
      :outlined="false" :rules="schema.rules" name="Date"/>

    <kurcc-time-picker
      v-if="!options.showDate && options.showTime" :outlined="false"
      :rules="schema.rules" :time.sync="localItem" name="Time"/>

    <validation-provider
      v-if="options.showDate && options.showTime" v-slot="{ errors }" :rules="schema.rules"
      class="w-100" name="Date & Time">
      <v-datetime-picker v-model="localItem" label="Date & Time" time-format="HH:mm">
        <template slot="dateIcon">
          <v-icon>mdi-calendar</v-icon>
        </template>
        <template slot="timeIcon">
          <v-icon>mdi-clock</v-icon>
        </template>
      </v-datetime-picker>
      <div v-if="errors.length" class="v-application v-messages error--text v-messages__message">
        {{ errors[0] }}
      </div>
    </validation-provider>
  </v-container>
</template>

<script>
import { updateQuestionField } from '@/modules/inspection/helpers/helpers'

export default {
  name: 'KurccFormDateTimeResponse',
  props: ['schema'],
  components: {
    KurccDatePicker: () => import('@/modules/inspection/components/KurccDatePicker'),
    KurccTimePicker: () => import('@/modules/inspection/components/KurccTimePicker')
  },
  data () {
    return {
      updateQuestionField
    }
  },
  computed: {
    options () {
      return this.schema.item.options
    },
    indexes () {
      return this.schema.indexes
    },
    localItem: {
      get () {
        return this.schema.item.answer
      },
      set (v) {
        this.updateQuestionField('answer', v, this.indexes)
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-text-field {
  width: 180px;
}
</style>
