export default [
  {
    path: '/more',
    name: 'more',
    component: () => import('../pages/KurccMorePage'),
    meta: {
      auth: true
    }
  },
  {
    path: '/more/sites',
    name: 'sites',
    component: () => import('../pages/KurccSitesPage'),
    meta: {
      auth: true
    }
  },
  {
    path: '/more/site/:site_id/branches',
    name: 'branches',
    component: () => import('../pages/KurccSiteBranchesPage'),
    meta: {
      auth: true
    }
  }
]
