import {
  CLONE_INSPECTION_FORM_QUESTION,
  DELETE_INSPECTION_FORM_QUESTION,
  RESET_INSPECTION_FORM,
  UPDATE_INSPECTION_FORM,
  UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD,
  UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD
} from '@/modules/app/store/mutation-types'
import lodash from 'lodash'
import { defaultForm } from '@/modules/inspection/constants/default-form'

export default {
  [UPDATE_INSPECTION_FORM] (state, form) {
    state.form = form
  },
  [UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD] (state, payload) {
    const {
      pageIndex,
      grandparentIndex,
      parentIndex,
      questionIndex,
      field,
      value
    } = payload
    if (existAndGteZero(grandparentIndex)) {
      (state.form.pages[pageIndex].questions[grandparentIndex].questions[parentIndex].questions[questionIndex])[field] = value
    } else if (existAndGteZero(parentIndex)) {
      (state.form.pages[pageIndex].questions[parentIndex].questions[questionIndex])[field] = value
    } else {
      (state.form.pages[pageIndex].questions[questionIndex])[field] = value
    }
  },
  [UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD] (state, payload) {
    const {
      pageIndex,
      grandparentIndex,
      parentIndex,
      questionIndex,
      field,
      value
    } = payload
    if (existAndGteZero(grandparentIndex)) {
      (state.form.pages[pageIndex].questions[grandparentIndex].questions[parentIndex].questions[questionIndex]).options[field] = value
    } else if (existAndGteZero(parentIndex)) {
      (state.form.pages[pageIndex].questions[parentIndex].questions[questionIndex]).options[field] = value
    } else {
      (state.form.pages[pageIndex].questions[questionIndex]).options[field] = value
    }
  },
  [CLONE_INSPECTION_FORM_QUESTION] (state, payload) {
    const {
      pageIndex,
      question,
      insertIndex,
      parentIndex,
      grandparentIndex,
      parentId
    } = payload
    const q = lodash.cloneDeep(question)
    q.id = new Date().getTime()
    if (parentId) {
      q.options.parentId = parentId
      q.options.repeatSection = false
    }

    const page = state.form.pages[pageIndex]

    if (existAndGteZero(grandparentIndex)) {
      page.questions[grandparentIndex].questions[parentIndex].questions.splice(insertIndex, 0, q)
    } else if (existAndGteZero(parentIndex)) {
      page.questions[parentIndex].questions.splice(insertIndex, 0, q)
    } else {
      page.questions.splice(payload.insertIndex, 0, q)
    }
  },
  [DELETE_INSPECTION_FORM_QUESTION] (state, payload) {
    const {
      pageIndex,
      parentIndex,
      grandparentIndex,
      questionIndex
    } = payload
    if (existAndGteZero(grandparentIndex)) {
      state.form.pages[pageIndex].questions[grandparentIndex].questions[parentIndex].questions.splice(questionIndex, 1)
    } else if (existAndGteZero(parentIndex)) {
      state.form.pages[pageIndex].questions[parentIndex].questions.splice(questionIndex, 1)
    } else {
      state.form.pages[pageIndex].questions.splice(questionIndex, 1)
    }
  },
  [RESET_INSPECTION_FORM] (state) {
    state.form = defaultForm
  }
}

const existAndGteZero = value => {
  return !lodash.isNil(value) && lodash.gte(value, 0)
}
