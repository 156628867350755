export default [
  {
    path: '/auth/index',
    name: 'login-index',
    component: () => import('@/modules/auth/pages/KurccLoginIndexPage'),
    meta: {
      auth: false
    }
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/modules/auth/pages/KurccLoginPage'),
    meta: {
      auth: false
    }
  },
  {
    path: '/auth/verify',
    name: 'login-verify-code',
    component: () => import('@/modules/auth/pages/KurccVerifyCodePage'),
    meta: {
      auth: false
    }
  },
  {
    path: '/403',
    name: 'forbidden',
    component: () => import('@/modules/auth/pages/KurccForbiddenPage')
  },
  {
    path: '/404',
    name: 'not-found',
    component: () => import('@/modules/auth/pages/KurccNotFoundPage')
  }
]
