// App
export const SET_SNACKBAR_DISPLAY_STATE = 'SET_SNACKBAR_DISPLAY_STATE'
export const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE'
export const SET_SNACKBAR_COLOR = 'SET_SNACKBAR_COLOR'
export const SHOW_SNACKBAR_SUCCESS = 'SHOW_SNACKBAR_SUCCESS'
export const SHOW_SNACKBAR_ERROR = 'SHOW_SNACKBAR_ERROR'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SET_IS_SEARCHING = 'SET_IS_SEARCHING'
export const SET_IS_SEARCH_BOX_OPEN = 'SET_IS_SEARCH_BOX_OPEN'
export const SET_FILTER = 'SET_FILTER'
export const RESET_FILTER = 'RESET_FILTER'

// Forms
export const UPDATE_INSPECTION_FORM = 'UPDATE_INSPECTION_FORM'
export const UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD = 'UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD'
export const UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD = 'UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD'
export const CLONE_INSPECTION_FORM_QUESTION = 'CLONE_INSPECTION_FORM_QUESTION'
export const DELETE_INSPECTION_FORM_QUESTION = 'DELETE_INSPECTION_FORM_QUESTION'
export const RESET_INSPECTION_FORM = 'RESET_INSPECTION_FORM'
