import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import light from '@/modules/app/themes/kurcc-light'
import { ar, en } from '@/assets/translations'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: { light }
  },
  lang: {
    locales: {
      ar,
      en
    },
    current: localStorage.getItem('locale') ?? 'en',
    rtl: localStorage.getItem('locale') === 'ar'
  }
})
