<template>
  <v-row align="center" justify="start">
    <v-col cols="12">
      <v-card>
        <vueSignature :key="componentKey" ref="signature" :disabled="disabled" :h="'200px'" :sigOption="option"
                      :w="'100%'"/>
      </v-card>
    </v-col>
    <v-col class="text-start">
      <v-btn class="mt-2 me-2" color="primary" small @click="save">Save</v-btn>
      <v-btn class="mt-2" color="error" small @click="clear">Clear</v-btn>
    </v-col>
    <v-col v-if="schema.showImageInput" cols="auto">
      <kurcc-image-input :image.sync="dataUrl" :name="`${item.title} Photo`" :only-icon="true" rules=""/>
    </v-col>
    <v-col cols="12">
      <validation-provider ref="provider" v-slot="{ errors }" :name="item.title ? item.title : 'This field'"
                           :rules="schema.rules" class="w-100">
        <v-text-field v-model="item.answer" :error-messages="errors" class="d-none"/>
        <div v-if="errors.length" class="v-application v-messages error--text v-messages__message">
          {{ errors[0] }}
        </div>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import vueSignature from 'vue-signature'
import { abstractField } from 'vue-form-generator'
import { updateQuestionField, urlToFile } from '@/modules/inspection/helpers/helpers'
import Compressor from 'compressorjs'

export default {
  name: 'KurccFormSignatureResponse',
  mixins: [abstractField],
  components: {
    KurccImageInput: () => import('@/modules/inspection/components/KurccImageInput'),
    vueSignature
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    schema: Object
  },
  data () {
    return {
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)'
      },
      dataUrl: undefined,
      updateQuestionField,
      urlToFile,
      componentKey: new Date().getTime()
    }
  },
  computed: {
    item () {
      return this.schema.item
    },
    indexes () {
      return this.schema.indexes
    },
    localItem: {
      get () {
        return this.schema.item.answer
      },
      set (v) {
        this.updateQuestionField('answer', v, this.indexes)
      }
    }
  },
  watch: {
    dataUrl: 'fromDataURL',
    localItem: {
      handler: function (v) {
        if (v === null) {
          this.componentKey++
        }
        setTimeout(() => {
          this.$refs.signature.fromDataURL(this.$lodash.cloneDeep(v))
        }, 0.5)
        // asd
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    save () {
      // let jpeg = this.$refs.signature.save('image/jpeg')
      // let svg = this.$refs.signature.save('image/svg+xml');
      this.compressAndUpdateImageField(this.$refs.signature.save())
      this.validate()
    },
    clear () {
      this.$refs.signature.clear()
      this.localItem = null
      this.validate()
    },
    validate () {
      this.$refs.provider.validate()
    },
    fromDataURL () {
      let img = null

      if (this.dataUrl) {
        img = URL.createObjectURL(this.dataUrl)
      } else {
        img = this.$lodash.cloneDeep(this.dataUrl)
      }

      this.$refs.signature.fromDataURL(img)
    },
    async compressAndUpdateImageField (file) {
      this.urlToFile(file, new Date().getTime(), 'image/jpeg')
        .then(file => {
          // eslint-disable-next-line no-new
          new Compressor(file, {
            quality: 0.6,
            maxWidth: 2000,
            minWidth: 2000,
            mimeType: 'image/jpeg',
            success: (result) => {
              this.localItem = result
            },
            error (err) {
              console.log('err', err.message)
            }
          })
        })
    }
  }
}
</script>
