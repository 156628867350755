import dayjs from 'dayjs'

export default class Group {
  constructor (
    id = null,
    name = 'Default Group',
    description = '',
    logo = null,
    enabled = 1,
    object = '',
    staffCount = 0,
    upcomingInspectionsCount = 0,
    createdAt = ''
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.logo = logo
    this.enabled = enabled
    this.object = object
    this.staff_count = staffCount
    this.upcoming_inspections_count = upcomingInspectionsCount
    this.created_at = createdAt
  }

  static fromJson (json) {
    return new Group(
      json.id,
      json.name,
      json.description,
      json.logo?.original,
      json.enabled,
      json.object,
      json.staff_count,
      json.upcoming_inspections_count,
      dayjs(json.created_at).format('YYYY-MM-DD'))
  }

  static fromJsonArray (json) {
    const result = []
    json.forEach(group => {
      result.push(this.fromJson(group))
    })
    return result
  }
}
