import { ar } from 'vuetify/es5/locale'

export default {
  ...ar,

  general: {
    close: 'Close',
    bottomNavigation: {
      inspections: 'Inspections',
      upcoming: 'Upcoming',
      today: 'Today',
      groups: 'Groups',
      profile: 'Profile',
      settings: 'Settings',
      more: 'More'
    },
    appBarTabs: {
      upcoming: {
        routine: 'routine',
        custom: 'custom',
        revisit: 'revisit'
      },
      today: {
        inspections: 'inspections',
        started: 'started',
        done: 'done'
      },
      group: {
        inspections: 'inspections',
        members: 'members'
      }
    },
    filter: {
      applyFilter: 'Apply Filter',
      sortAndOrder: 'Sort & Order',
      sortBy: 'Sort by',
      orderBy: 'Order by',
      inspectionAssignmentStatus: 'Inspection assignment status',
      assignedToInspector: 'Assigned to inspector',
      notAssignedToInspector: 'Not assigned to inspector',
      categoriesOfSites: 'Categories of Sites',
      categories: 'Categories',
      inspectorsGroups: 'Inspectors groups',
      groups: 'Groups',
      groupAssignmentStatus: 'Group assignment status',
      assignedToGroup: 'Assigned to group',
      notAssignedToGroup: 'Not assigned to group',
      inspectionType: 'Inspection type',
      routine: 'Routine',
      custom: 'Custom',
      revisit: 'Revisit'
    },
    inspection: {
      assignee: 'Assignee:',
      dueDate: 'Due date:',
      type: 'Type:',
      siteDialog: {
        sitesPage: 'Site\'s page',
        sectionHeaders: {
          generalInformation: 'General information',
          branchContactInfos: 'Branch contact infos',
          employeeInfo: 'Employee info',
          inspectionStatus: 'Inspection status'
        },
        sectionFields: {
          siteName: 'Site Name',
          lastInspection: 'Last Inspection',
          nextInspection: 'Next Inspection',
          category: 'Category',
          grade: 'Grade',
          address: 'Address',
          email: 'Email',
          branchName: 'Branch name',
          managersName: 'Manager\'s name',
          managersPhone: 'Manager\'s phone',
          inspectionDueDateStatus: 'Inspection due date status',
          inspector: 'Inspector',
          deadlineDays: 'Deadline (days)',
          nopInspectionIsDue: 'No inspection is due',
          notAssignedYet: 'Not assigned yet'
        },
        chooseForToday: 'Choose for today',
        assignToMe: 'Assign to me',
        assignTo: 'Assign to',
        viewInspection: 'View Inspection',
        startInspection: 'Start Inspection'
      }
    }
  },
  pages: {
    auth: {
      forbiddenMessage: 'تم إدخال في الخانة الخطأ',
      login: 'تسجيل الدخول',
      takeMeBack: 'ارجعني الي',
      home: 'الرئيسية',
      send: 'ارسال',
      smsCostNotification: 'قد يتم إرسال رسالة نصية عند الاستمرار',
      number: 'رقم',
      verificationTitle: 'عنوان التحقق',
      sendVerificationTo: 'ارسل التحقق الي',
      editMyNumber: 'تعديل الرقم',
      resendCodeIn: 'اعادة ارسال الرمز الي ',
      resendCode: 'اعادة ارسال الرمز',
      verificationCode: 'رمؤ التحقق'
    },
    groups: {
      members: 'اعضاء',
      addMember: 'اضافة العضو',
      admin: 'المسئول',
      member: 'عضو',
      upcomingInspections: 'التفاتيش القادمة: ',
      inspectionMemberCount: 'الاعضاء '
    },
    inspections: {
      assignToBtn: 'عين الي',
      inspectors: 'المفتشين',
      addToInspectorBtn: 'اضف الي المفتش'
    },
    profile: {
      editProfile: 'تعديل الملف المستخدم',
      firstName: 'اسم الاول',
      lastName: 'اسم العائلة',
      email: 'ايميل',
      emailRetype: 'ايميل (Retype)',
      newPassword: 'كلمةالمرور الجديد',
      newPasswordRetype: 'كلمة المرور الجديد (Retype)',
      reset: 'اعادة',
      update: 'التحديث'
    },
    settings: {
      signOut: 'تسجيل الخروج',
      reset: 'اعادة',
      update: 'التحديث'
    },
    upcoming: {
      chooseForToday: 'اختر لليوم'
    },
    more: {
      sites: 'Sites',
      profile: 'الملف المستخدم',
      settings: 'اعدادات',
      yes: 'نعم',
      no: 'لا',
      branchCount: 'عدد الأفرع: ',
      enabled: 'تم التفعيل: ',
      branchPage: {
        title: 'Branch\'s page',
        sections: {
          generalInfo: 'معلومات عامة',
          branchContactInfo: 'معلومات التواصل مع الفرع',
          employeeInfo: 'معلومات الموظف',
          inspectionStatus: 'حالة التفتيش',
          titles: {
            siteName: 'اسم الموقع',
            lastInspection: 'أخر تفتيش',
            nextInspection: 'التفتيش القادم',
            category: 'صنف',
            grade: 'التقييم',
            address: 'العنوان',
            email: 'ايميل',
            branchName: 'اسم الفرع',
            managerName: 'اسم المدير',
            managerPhone: 'رقم هاتف مدير',
            inspectionDueDate: 'Inspection due date status',
            inspector: 'المفتش',
            deadlineDays: 'Deadline (days)',
            noDueInspection: 'لا يوجد تفاتيش مطلوبة',
            notAssigned: 'لم يتم التعيين'
          }
        }
      }
    }
  },
  languageSelectorOptions: {
    english: 'انگلیسی',
    arabic: 'عربی'
  },
  snackbar: {
    created: 'تم انشاء الصنف',
    updated: 'تم تحديث الصنف',
    deleted: 'تم مسح الصنف',
    networkError: 'خطا بالشبكة',
    notFound: 'لم يتم العثور على المورد',
    generalError: 'Something went wrong!'
  }
}
