import Vue from 'vue'
import {
  SET_SNACKBAR_COLOR,
  SET_SNACKBAR_DISPLAY_STATE,
  SET_SNACKBAR_MESSAGE
} from '@/modules/app/store/mutation-types'

export default {
  requestPassword ({ commit }, payload) {
    return Vue.axios.post('/otp/code', payload)
      .then(() => {
        if (payload.resend) {
          commit(SET_SNACKBAR_MESSAGE, 'Login Code Requested')
          commit(SET_SNACKBAR_COLOR, 'green')
          commit(SET_SNACKBAR_DISPLAY_STATE, true)
        }

        localStorage.setItem('phone_number', payload.phone_number)
        Vue.router.push({ name: 'login-verify-code' })
      }).catch(err => console.log(err))
  },
  login ({ commit }, payload) {
    payload = payload || {}
    return new Promise((resolve, reject) => {
      Vue.auth.login({
        data: payload,
        staySignedIn: true
      }).then((res) => {
        resolve(res)
      }).catch(err => {
        if (err.response.status === 403) {
          commit(SET_SNACKBAR_MESSAGE, err.response.data.message)
          commit(SET_SNACKBAR_COLOR, 'red')
          commit(SET_SNACKBAR_DISPLAY_STATE, true)
        }
        console.log(err)
        reject(err)
      })
    })
  },
  logout () {
    return new Promise((resolve, reject) => {
      Vue.auth.logout().then((res) => {
        localStorage.removeItem('refresh_token')
        resolve(res)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }
}
