<template>
  <validation-provider v-slot="{ errors }" :name="title" :rules="schema.rules" class="w-100">
    <v-text-field v-model="localItem" :error-messages="errors" :min="0" :placeholder="schema.placeholder"
                  type="number"/>
  </validation-provider>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { updateQuestionField } from '@/modules/inspection/helpers/helpers'

export default {
  name: 'KurccFormNumber',
  mixins: [abstractField],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    schema: Object
  },
  data () {
    return {
      updateQuestionField
    }
  },
  computed: {
    title () {
      return this.$lodash.isEmpty(this.schema.item.title) ? 'This field' : this.schema.item.title
    },
    indexes () {
      return this.schema.indexes
    },
    localItem: {
      get () {
        return this.schema.item.answer
      },
      set (v) {
        this.updateQuestionField('answer', v, this.indexes)
      }
    }
  }
}
</script>
