export default [
  {
    path: '/today',
    name: 'today',
    component: () => import('../pages/KurccTodayPage'),
    meta: {
      auth: true
    }
  }
]
