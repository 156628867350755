import { en } from 'vuetify/es5/locale'

export default {
  ...en,

  general: {
    close: 'Close',
    bottomNavigation: {
      inspections: 'Inspections',
      upcoming: 'Upcoming',
      today: 'Today',
      groups: 'Groups',
      profile: 'Profile',
      settings: 'Settings',
      more: 'More'
    },
    appBarTabs: {
      upcoming: {
        routine: 'routine',
        custom: 'custom',
        revisit: 'revisit'
      },
      today: {
        inspections: 'inspections',
        started: 'started',
        done: 'done'
      },
      group: {
        inspections: 'inspections',
        members: 'members'
      }
    },
    filter: {
      applyFilter: 'Apply Filter',
      sortAndOrder: 'Sort & Order',
      sortBy: 'Sort by',
      orderBy: 'Order by',
      inspectionAssignmentStatus: 'Inspection assignment status',
      assignedToInspector: 'Assigned to inspector',
      notAssignedToInspector: 'Not assigned to inspector',
      categoriesOfSites: 'Categories of Sites',
      categories: 'Categories',
      inspectorsGroups: 'Inspectors groups',
      groups: 'Groups',
      groupAssignmentStatus: 'Group assignment status',
      assignedToGroup: 'Assigned to group',
      notAssignedToGroup: 'Not assigned to group',
      inspectionType: 'Inspection type',
      routine: 'Routine',
      custom: 'Custom',
      revisit: 'Revisit'
    },
    inspection: {
      assignee: 'Assignee:',
      dueDate: 'Due date:',
      type: 'Type:',
      siteDialog: {
        sitesPage: 'Site\'s page',
        sectionHeaders: {
          generalInformation: 'General information',
          branchContactInfos: 'Branch contact infos',
          employeeInfo: 'Employee info',
          inspectionStatus: 'Inspection status'
        },
        sectionFields: {
          siteName: 'Site Name',
          lastInspection: 'Last Inspection',
          nextInspection: 'Next Inspection',
          category: 'Category',
          grade: 'Grade',
          address: 'Address',
          email: 'Email',
          branchName: 'Branch name',
          managersName: 'Manager\'s name',
          managersPhone: 'Manager\'s phone',
          inspectionDueDateStatus: 'Inspection due date status',
          inspector: 'Inspector',
          deadlineDays: 'Deadline (days)',
          nopInspectionIsDue: 'No inspection is due',
          notAssignedYet: 'Not assigned yet'
        },
        chooseForToday: 'Choose for today',
        assignToMe: 'Assign to me',
        assignTo: 'Assign to',
        viewInspection: 'View Inspection',
        startInspection: 'Start Inspection'
      }
    }
  },
  pages: {
    auth: {
      forbiddenMessage: 'You entered a forbidden place!',
      login: 'Login',
      takeMeBack: 'Take me back to',
      home: 'Home',
      send: 'Send',
      smsCostNotification: 'By continuing you may receive an SMS\n' +
        '                  for verification. Massage and data rate\n' +
        '                  may apply.',
      number: 'Number',
      verificationTitle: 'Enter Verification code',
      sendVerificationTo: 'Enter the verification code that send to',
      editMyNumber: 'Edit my number',
      resendCodeIn: 'Resend code in: ',
      resendCode: 'Resend code',
      verificationCode: 'Verification Code'
    },
    groups: {
      members: 'Members',
      addMember: ' ADD MEMBER',
      admin: 'Admin',
      member: 'Member',
      upcomingInspections: 'Upcoming inspections: ',
      inspectionMemberCount: 'Members: '
    },
    inspections: {
      assignToBtn: 'ASSIGN TO',
      inspectors: 'Inspectors',
      addToInspectorBtn: 'ADD TO THE INSPECTOR'
    },
    profile: {
      editProfile: 'Edit Profile',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'email',
      emailRetype: 'Email (Retype)',
      newPassword: 'New Password',
      newPasswordRetype: 'New Password (Retype)',
      reset: 'Reset',
      update: 'Update'
    },
    settings: {
      signOut: 'Sign out',
      reset: 'Reset',
      update: 'Update'
    },
    upcoming: {
      chooseForToday: 'CHOOSE FOR TODAY'
    },
    more: {
      sites: 'Sites',
      profile: 'Profile',
      settings: 'Settings',
      yes: 'Yes',
      no: 'No',
      branchCount: 'Branch count: ',
      enabled: 'Enabled: ',
      branchPage: {
        title: 'Branch\'s page',
        sections: {
          generalInfo: 'General information',
          branchContactInfo: 'Branch contact infos',
          employeeInfo: 'Employee info',
          inspectionStatus: 'Inspection status',
          titles: {
            siteName: 'Site Name',
            lastInspection: 'Last Inspection',
            nextInspection: 'Next Inspection',
            category: 'Category',
            grade: 'Grade',
            address: 'Address',
            email: 'Email',
            branchName: 'Branch name',
            managerName: 'Manager\'s name',
            managerPhone: 'Manager\'s phone',
            inspectionDueDate: 'Inspection due date status',
            inspector: 'Inspector',
            deadlineDays: 'Deadline (days)',
            noDueInspection: 'No inspection is due',
            notAssigned: 'Not assigned yet'
          }
        }
      }
    }
  },
  languageSelectorOptions: {
    english: 'English',
    arabic: 'Arabic'
  },
  snackbar: {
    created: 'Item Created',
    updated: 'Item Updated',
    deleted: 'Item Deleted',
    networkError: 'A network error occurred',
    notFound: 'Resource not found',
    generalError: 'Something went wrong!'
  }
}
